@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300..700&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

a {
    @apply text-danube-500 hover:underline decoration-2 underline-offset-2;
}

.content {
    h3 {
        @apply text-danube-600 font-sans text-xl my-4;
    }

    img {
        @apply md:float-right rounded-lg shadow md:w-1/2 w-4/6 md:mx-0 md:ml-2 mx-auto my-2;
    }

    ul li {
        @apply list-disc ml-8;
    }


    ol>li:before {
        content: "Step " counter(step-counter);
        float: left;
        @apply text-danube-500 tabular-nums lining-nums w-16 -ml-16;
    }

    ol li {
        counter-increment: step-counter;
        @apply mb-2 pl-16;
    }

    ol:first-child {
        counter-reset: step-counter;
    }
}

iconify-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}
